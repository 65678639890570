<template>
  <OrdersComponent />
</template>


<script>

import OrdersComponent from "@/components/orders/ordersComponent.vue";

export default {
  name: "OrdersView",
  components: {
    OrdersComponent,
  },
};
</script>