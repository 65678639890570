<template>
  <div class="mb-2">
    <v-card outlined color="transparent">
      <v-card-text class="pa-0">
        <v-card>
          <v-card-title>
            <v-row no-gutters justify="space-between" align="center" class="mx-4">
              <v-col cols="3">
                {{$t("sideBar.orders")}}
              </v-col>
<!--              <v-col cols="auto">
                <v-btn @click="createOrder()">
                  {{$t('btn.add_an_order')}}
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </v-col>-->
            </v-row>
          </v-card-title>
          <v-tabs
              v-model="selectedTab"
              :background-color="$vuetify.theme.dark ? 'grey darken-2' : 'white lighten-1'"
              :color="getStatusColor(statuses[selectedTab]?.value)"
              show-arrows
              grow
          >
            <v-tab
                v-for="(status, index) in statuses"
                :key="status.value"
                @click="fetchFilteredOrders(status)"
            >
              {{ status.text }}
            </v-tab>
          </v-tabs>

          <v-card-text>
            <v-data-table
                :headers="streamsHeaders"
                :items="ordersList"
                item-key="id"
                :items-per-page="ordersList.length"
                dense
                outlined
                hide-default-footer
                :height="getTableHeight"
            >
              <template v-slot:item.status="{ item }">
                <v-chip class="my-1" :color="getStatusColor(item.status)" dark>
                  {{ $t(`form.${item?.status}`) }}
                </v-chip>
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <td :colspan="12">
                    <v-progress-linear
                        v-if="isLoadingItems"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    <div
                        v-observer="{ nextPage: nextPage }"
                        class="observer"
                        v-if="!loadMoreDisabled && !isLoadingItems"
                    ></div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <add-edit-order-dialog
        v-if="orderDialog"
        :visible="orderDialog"
        @close="orderDialog = false"
        @orderCreated="addCreatedOrder"
        :type="'create'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import ordersService from "@/services/request/orders/ordersService";
import AddEditOrderDialog from "@/components/orders/addEditOrderDialog.vue";
import dataTableMixin from "@/mixins/dataTableMixin";
import loader from "@/mixins/loader";

export default {
  components: { AddEditOrderDialog, DeleteConfirmDialog },
  mixins: [notifications, dataTableMixin, loader],
  data: () => ({
    ordersList: [],
    showDeleteDialog: false,
    orderDialog: false,
    selectedTab: 0,
    offsetBottom: 315
  }),
  computed: {
    ...mapGetters(["currentLanguage"]),
    streamsHeaders() {
      return [
        {text: this.$t('form.client_name'), value: 'client_name'},
        {text: this.$t('settings.price'), value: 'sale_price'},
        {text: this.$t('form.processing_type'), value: 'processing_type'},
        {text: this.$t('settings.phone_number'), value: 'client_phone'},
        {text: this.$t('form.status'), value: 'status'},
        {text: '', value: 'action', sortable: false},
      ];
    },
    statuses() {
    return [
      {text: this.$t('form.all'), value: 'all'},
      {text: this.$t('form.new'), value: 'new'},
      {text: this.$t('form.not_processed'), value: 'not_processed'},
      {text: this.$t('form.missed_call'), value: 'missed_call'},
      {text: this.$t('form.in_progress'), value: 'in_progress'},
      {text: this.$t('form.confirmed'), value: 'confirmed'},
      {text: this.$t('form.rejected'), value: 'rejected'},
      {text: this.$t('form.shipped'), value: 'shipped'},
      {text: this.$t('form.not_purchased'), value: 'not_purchased'},
      {text: this.$t('form.purchased'), value: 'purchased'},
    ];
  },
  },
  methods: {
    createOrder() {
      this.orderDialog = true;
    },
    addCreatedOrder(order) {
      if(this.selectedTab == 2 || this.selectedTab == 0) {
        this.ordersList?.unshift(order);
      }
    },
    async fetchOrders(status, page) {
      this.isLoadingItems = true;
      try {
        const params = {};
        if (status?.value && status?.value !== 'all') {
          params.status = status.value;
        }
        params.page = page;
        params.per_page = this.perPage;
        const response = await ordersService.getOrders(params);
        if (response && response.length >= 0) {
          if (page > 1) {
            this.ordersList = [...this.ordersList, ...response];
          } else {
            this.ordersList = response;
          }
        }
         this.loadMoreDisabled = response.length < this.perPage;
      } catch (e) {
        this.isLoadingItems = false;
        console.log(e);
      } finally {
        this.isLoadingItems = false;
      }
    },
    fetchFilteredOrders(status) {
      this.currentPage = 1;
      this.fetchOrders(status, this.currentPage);
    },
    async nextPage() {
      this.currentPage += 1;
      this.isLoadingItems = true;
      await this.fetchOrders(this.statuses[this.selectedTab], this.currentPage);
      this.isLoadingItems = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 'new':
          return 'blue';          
        case 'not_processed':
          return 'orange';        
        case 'missed_call':
          return 'red';          
        case 'in_progress':
          return 'purple';        
        case 'confirmed':
          return 'green';         
        case 'rejected':
          return 'red darken-2';  
        case 'shipped':
          return 'cyan';          
        case 'not_purchased':
          return 'brown';         
        case 'purchased':
          return 'green darken-3';
        default:
          return 'grey';         
      }
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.fetchOrders(0, 1);
    this.setLoading(false);
  }
};
</script>

<style scoped>
</style>
